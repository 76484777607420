import React from "react"

import "../assets/css/main.css"
import { Helmet } from "react-helmet"
const play = require('../images/JamHot-Coming-Soon.jpg');

const LandingPage = ({ children }) => {


    return (
        <>
            <Helmet 
                title='JamHot coming soon!'
            />
            <div className="landing">
                <img src={play} alt="JamHot coming soon!" />
            </div>
        </>
    )
}


export default LandingPage
